import PropTypes from 'prop-types';

const creditCardConfiguration = PropTypes.shape({
  shouldShowAddressFields: PropTypes.bool.isRequired,
  shouldShowCvvDescription: PropTypes.bool.isRequired,
  shouldShowZipFieldOnly: PropTypes.bool.isRequired,
  shouldShowNameField: PropTypes.bool.isRequired,
  useTextTooltipForCvvPopup: PropTypes.bool.isRequired,
  shouldShowPinField: PropTypes.bool.isRequired,
  googleCaptchaKey: PropTypes.string,
  googleCaptchaSecondaryV2Key: PropTypes.string,
  postNewCreditCardOnSave: PropTypes.bool.isRequired,
  fieldLabels: PropTypes.shape({
    stateLabel: PropTypes.string,
    zipLabel: PropTypes.string,
    cvvLabel: PropTypes.string.isRequired,
    defaultStateValue: PropTypes.string,
    defaultCountryValue: PropTypes.string,
    pinLabel: PropTypes.string,
  }).isRequired,
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      abbreviation: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  states: PropTypes.arrayOf(
    PropTypes.shape({
      abbreviation: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  braintreeClientToken: PropTypes.string,
});

export default {
  creditCardConfiguration,
};
