import React from 'react';

interface Props {
  successMessage?: string;
}

const PaymentSuccessMessage = ({ successMessage }: Props) => {
  if (!successMessage) {
    return null;
  }
  return (
    <div className="paymentMethodInfo-successMessage">
      <div>{successMessage}</div>
    </div>
  );
};

export default PaymentSuccessMessage;
