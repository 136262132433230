import Logger from '../common/Logger';

export const triggerPurchaseAttemptTelemetry = (planTelemetryName: string) => {
  try {
    const telemetryEvenName = `purchaseattempt.${planTelemetryName}`;

    Logger.accessTelemetry().increment(telemetryEvenName);
  } catch (e) {
    console.error(e);
  }
};
