import client from 'braintree-web/client';
import dataCollector from 'braintree-web/data-collector';
import paypalCheckout from 'braintree-web/paypal-checkout';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore
import ScriptLoader from 'react-render-props-script-loader';

import { Telemetry } from '@videoblocks/kafka-rest-client';

import { triggerPurchaseAttemptTelemetry } from './paymentMethodFormUtils';

import './paypal.less';

interface Props {
  paypalClientId: string;
  paypalEnvironment: string;
  braintreeClientToken: string;
  onApprove: (payload: object) => void;
  planTelemetryName?: string;
  step: string;
  onError: (object) => void;
}

interface State {
  paypalCheckoutInstance: any;
  dataCollectorInstance: any;
}

class Paypal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      paypalCheckoutInstance: null,
      dataCollectorInstance: null,
    };

    client.create(
      {
        authorization: this.props.braintreeClientToken,
      },
      (clientErr, clientInstance) => {
        if (clientErr) {
          return;
        }
        dataCollector.create(
          {
            client: clientInstance,
          },
          (err, dataCollectorInstance) => {
            if (err) {
              return;
            }
            this.setState({
              dataCollectorInstance,
            });
          }
        );

        paypalCheckout.create(
          {
            client: clientInstance,
          },
          (paypalCheckoutErr, paypalCheckoutInstance) => {
            if (paypalCheckoutErr) {
              return;
            }

            this.setState({ paypalCheckoutInstance });
          }
        );
      }
    );
  }

  onCancel() {
    // do nothing
  }

  render() {
    const {
      onApprove,
      paypalEnvironment,
      paypalClientId,
      planTelemetryName,
      step,
      onError,
    } = this.props;
    const { paypalCheckoutInstance, dataCollectorInstance } = this.state;

    if (!paypalCheckoutInstance) {
      return null;
    }

    return (
      <ScriptLoader
        src={`https://www.paypal.com/sdk/js?client-id=${paypalClientId}&vault=true&disable-funding=credit,card`}
      >
        {({ loading, error }) => {
          if (loading || error) {
            return (
              <div className="paypalLoading-container">
                <img
                  className="paypalLoading-indicator"
                  src={`${__ASSETS_COMMON_IMAGES_URL__}/admin/loading.gif`}
                  alt="PayPal loading"
                  width={16}
                  height={16}
                />
              </div>
            );
          }

          // @ts-expect-error Unsure where paypal is coming from.
          const PaypalButton = paypal.Buttons.driver('react', {
            React,
            ReactDOM,
          });

          return (
            <div className="relative">
              <PaypalButton
                env={paypalEnvironment}
                style={{
                  size: 'medium',
                  color: 'white',
                  shape: 'pill',
                  label: 'paypal',
                  tagline: false,
                }}
                createBillingAgreement={() => {
                  Telemetry.increment(`paypal.${step}.started`);
                  return paypalCheckoutInstance.createPayment({
                    flow: 'vault',
                    enableShippingAddress: true,
                  });
                }}
                onApprove={(data) => {
                  Telemetry.increment(`paypal.${step}.approved`);
                  paypalCheckoutInstance
                    .tokenizePayment(data)
                    .then(function (payload) {
                      const deviceData = dataCollectorInstance.deviceData;
                      onApprove({ ...payload, deviceData });
                    });
                }}
                onCancel={() => {
                  Telemetry.increment(`paypal.${step}.cancelled`);
                  this.onCancel();
                }}
                onError={(err) => {
                  Telemetry.increment(`paypal.${step}.error`);
                  onError(err);
                }}
                onClick={() => {
                  Telemetry.increment(`paypal.${step}.clicked`);
                  triggerPurchaseAttemptTelemetry(planTelemetryName);
                }}
              />
            </div>
          );
        }}
      </ScriptLoader>
    );
  }
}

export default Paypal;
