import React, { Component } from 'react';

import { paymentMethods } from '../common/Constants';
import Logger from '../common/Logger';
import Paypal from './Paypal';
import { PaypalConfiguration } from './PaypalCreateSubscription';
import PaypalLoading from './PaypalLoading';

import './paypal.less';

const updatePaymentMethodRoute = '/api/signup/update';

interface Props {
  configuration: PaypalConfiguration;
  getJsonResponse: boolean;
  paymentMethodUpdateRoute: string;
  onUpdateSuccess: (data) => void;
  onUpdateError: () => void;
}

interface State {
  processingUpdateRequest: boolean;
}

export default class UpdatePaypalAccount extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      processingUpdateRequest: false,
    };

    this.onApprove = this.onApprove.bind(this);
    this.onError = this.onError.bind(this);
    this.onSuccess = this.onSuccess.bind(this);
  }

  onApprove(payload) {
    this.setState({
      processingUpdateRequest: true,
    });

    const { getJsonResponse } = this.props;

    const data = new FormData();
    const shippingAddress = payload.details.shippingAddress || {};

    data.append('payment_method', paymentMethods.PAYPAL.toString());
    data.append('payment_nonce', payload.nonce);

    data.append('fname', payload.details.firstName);
    data.append('lname', payload.details.lastName);

    data.append('address1', shippingAddress.line1);
    data.append('city', shippingAddress.city);
    data.append('state', shippingAddress.state);
    data.append('country', shippingAddress.countryCode);
    data.append('zip', shippingAddress.postalCode);

    data.append('device_data', payload.deviceData);

    const requestData = {
      body: data,
      method: 'POST',
      headers: getJsonResponse ? { Accept: 'application/json' } : null,
    };

    fetch(updatePaymentMethodRoute, requestData)
      .then(async (response) => {
        if (response.redirected) {
          return window.location.replace(response.url);
        }
        if (getJsonResponse) {
          const json = await response.json();
          if (json.success) {
            // @ts-ignore
            return this.onSuccess(response.data);
          }
        }
        return this.onError(response);
      })
      .catch((err) => {
        console.error(err);
        this.onError(err);
      });
  }

  onError(err) {
    this.setState({
      processingUpdateRequest: false,
    });
    Logger.error(err);
    this.props.onUpdateError();
  }

  onSuccess(data) {
    if (this.props.onUpdateSuccess) {
      this.props.onUpdateSuccess(data);
    }
  }

  render() {
    const { paypalClientId, paypalEnvironment, braintreeClientToken } =
      this.props.configuration;
    return this.state.processingUpdateRequest ? (
      <PaypalLoading />
    ) : (
      <div>
        <Paypal
          paypalClientId={paypalClientId}
          paypalEnvironment={paypalEnvironment}
          braintreeClientToken={braintreeClientToken}
          onApprove={this.onApprove}
          onError={this.onError}
          step="update"
        />
      </div>
    );
  }
}
