import client from 'braintree-web/client';
import dataCollector from 'braintree-web/data-collector';
import React, { useEffect, useState } from 'react';

interface DeviceDataInputProps {
  braintreeClientToken: string;
  onLoad?: (string) => void;
}
const DeviceDataInput = (props: DeviceDataInputProps) => {
  const { braintreeClientToken, onLoad = () => {} } = props;
  const [value, setValue] = useState('');

  useEffect(() => {
    client.create(
      { authorization: braintreeClientToken },
      (clientErr, clientInstance) => {
        if (!clientErr) {
          dataCollector.create(
            { client: clientInstance },
            (err, dataCollectorInstance) => {
              if (!err) {
                setValue(dataCollectorInstance.deviceData);
                onLoad(dataCollectorInstance.deviceData);
              }
            }
          );
        }
      }
    );
  }, []);

  return <input type="hidden" name="device_data" value={value} />;
};

export default DeviceDataInput;
