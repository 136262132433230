import React, { Component } from 'react';

import { paymentMethods } from '../common/Constants';
import PaypalViewOrUpdate from './PaypalViewOrUpdate';
import {
  PaymentMethodConfiguration,
  PaymentToken,
} from './SignUp/SignUpStateDefinition';
import ViewOrUpdateCreditCard from './ViewOrUpdateCreditCard';

interface Props {
  onPaymentUpdate?: (data: any) => void;
  paymentToken?: PaymentToken;
  paymentMethodConfiguration?: PaymentMethodConfiguration;
  toggleSubmitButton?: (data: object) => void;
  getClosePopUpHandler?: () => void;
  shouldHideCancelButtonWhenInvalidToken: boolean;
  showDisclaimerCheckbox: boolean;
  errorMessage?: string;
  saveButtonText: string;
  showPaymentDetailsHeader: boolean;
  shouldForceUpdateViewOnly: boolean;
  isTokenDeleted?: boolean;
  getJsonResponse?: boolean;
}

export default class ViewOrUpdatePaymentMethod extends Component<Props> {
  constructor(props) {
    super(props);
  }

  render() {
    if (this.props.isTokenDeleted) {
      return null;
    }
    const { paymentToken, paymentMethodConfiguration } = this.props;

    let paymentMethodViewOrUpdate;
    if (paymentToken.isTokenDeleted) {
      return null;
    }
    switch (paymentToken.paymentMethodType) {
      case paymentMethods.PAYPAL:
        paymentMethodViewOrUpdate = (
          <PaypalViewOrUpdate
            onPaymentUpdate={this.props.onPaymentUpdate}
            configuration={paymentMethodConfiguration.paypal.configuration}
            isTokenValid={paymentToken.isTokenValid}
            toggleSubmitButton={this.props.toggleSubmitButton}
            showPaymentDetailsHeader={this.props.showPaymentDetailsHeader}
            getClosePopUpHandler={this.props.getClosePopUpHandler}
            shouldHideCancelButtonWhenInvalidToken={
              this.props.shouldHideCancelButtonWhenInvalidToken
            }
            shouldForceUpdateViewOnly={this.props.shouldForceUpdateViewOnly}
            getJsonResponse={this.props.getJsonResponse}
            showDisclaimerCheckbox={this.props.showDisclaimerCheckbox}
          />
        );
        break;
      case paymentMethods.CARD:
      default:
        paymentMethodViewOrUpdate = (
          <ViewOrUpdateCreditCard
            onPaymentUpdate={this.props.onPaymentUpdate}
            configuration={paymentMethodConfiguration.creditCard}
            isTokenValid={paymentToken.isTokenValid}
            creditCard={paymentToken.tokenDetails}
            toggleSubmitButton={this.props.toggleSubmitButton}
            getClosePopUpHandler={this.props.getClosePopUpHandler}
            getJsonResponse={this.props.getJsonResponse}
            showDisclaimerCheckbox={this.props.showDisclaimerCheckbox}
            errorMessage={this.props.errorMessage}
            saveButtonText={this.props.saveButtonText}
            shouldHideCancelButtonWhenInvalidToken={
              this.props.shouldHideCancelButtonWhenInvalidToken
            }
            shouldForceUpdateViewOnly={this.props.shouldForceUpdateViewOnly}
          />
        );
        break;
    }

    return (
      <div className="paymentMethodInfo-container">
        {paymentMethodViewOrUpdate}
      </div>
    );
  }
}
