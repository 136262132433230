import React from 'react';

const PaypalLoading = () => (
  <div className="paypalLoading-container">
    <img
      className="paypalLoading-indicator"
      src={`${__ASSETS_COMMON_IMAGES_URL__}/admin/loading.gif`}
      alt="PayPal loading"
      width={20}
      height={20}
    />
  </div>
);

export default PaypalLoading;
