import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Telemetry } from '@videoblocks/kafka-rest-client';
import { ProgressiveImage } from '@videoblocks/storywind';

import CreditCardForm from './CreditCardForm';
import PaymentSuccessMessage from './PaymentSuccessMessage';
import schemas from './schemas';

const updatePaymentMethodRoute = '/api/signup/update';

// TODO: Convert to TS when there is more time OMFG-1016 and TOFU-431, but if you see this, feel free to convert it too.
export default class ViewOrUpdateCreditCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newCreditCardData: {
        cc_number: '',
        cc_exp_full: '',
        cc_cvv: '',
      },
      newCreditCardNumber: '',
      newCreditCardExpirationDate: '',
      newCreditCardCvv: '',
      editing: !props.isTokenValid,
      errors: {
        cc_number: '',
        cc_exp_full: '',
        cc_cvv: '',
        checkbox: '',
      },
      isTokenValid: props.isTokenValid,
      processing: false,
      errorMessage: props.errorMessage,
    };

    this.enableEditMode = this.enableEditMode.bind(this);
    this.disableEditMode = this.disableEditMode.bind(this);
    this.saveCreditCardAndDisableEditMode =
      this.saveCreditCardAndDisableEditMode.bind(this);
    this.handleCreditCardFormChange =
      this.handleCreditCardFormChange.bind(this);
    this.checkboxRef = React.createRef();
  }

  static propTypes = {
    onPaymentUpdate: PropTypes.func,
    configuration: schemas.creditCardConfiguration,
    toggleSubmitButton: PropTypes.func,
    getClosePopUpHandler: PropTypes.func,
    shouldHideCancelButtonWhenInvalidToken: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    showDisclaimerCheckbox: PropTypes.bool.isRequired,
    saveButtonText: PropTypes.string.isRequired,
    isTokenValid: PropTypes.bool,
  };

  render() {
    return this.shouldShowUpdateSection()
      ? this.renderUpdateCreditCardSection()
      : this.renderCreditCardOverviewSection();
  }

  shouldShowUpdateSection() {
    return (
      this.state.editing ||
      this.props.shouldForceUpdateViewOnly ||
      !(this.state.isTokenValid || this.hasValidUserSubmittedPaymentInfo())
    );
  }

  renderCreditCardOverviewSection() {
    return (
      <React.Fragment>
        <div className="paymentMethodInfo-row">
          <div className="paymentMethodInfo-col left" data-private="redact">
            <ProgressiveImage
              className={'ccs'}
              src="/assets/common/images/credit-card-icons.png"
              shouldLazyLoad={false}
              width={115}
              height={16}
            />
            <br />
            <div className="paymentMethodInfo-existingCreditCardDetails">
              Credit Card ending in{' '}
              {this.state.newCreditCardData.cc_number
                ? this.state.newCreditCardData.cc_number.substring(
                    this.state.newCreditCardData.cc_number.length - 4
                  )
                : this.props.creditCard.creditCardLastFour}
              <br />
              Expiration:{' '}
              {this.state.newCreditCardData.cc_exp_full ||
                this.props.creditCard.creditCardExpMonth +
                  '/' +
                  this.props.creditCard.creditCardExpYear.toString().slice(-2)}
            </div>
          </div>

          <div className="paymentMethodInfo-col right">
            <div
              role="button"
              tabIndex={0}
              className="paymentMethodInfo-edit"
              onClick={this.enableEditMode}
              onKeyDown={this.enableEditMode}
            >
              Edit Payment Method
            </div>
          </div>
        </div>
        <PaymentSuccessMessage successMessage={this.state.successMessage} />
      </React.Fragment>
    );
  }

  renderUpdateCreditCardSection() {
    return (
      <React.Fragment>
        <CreditCardForm
          configuration={this.props.configuration}
          onDataUpdated={this.handleCreditCardFormChange}
          errors={this.state.errors}
        />

        {this.props.showDisclaimerCheckbox && (
          <div className="paymentMethodInfo-disclaimer">
            <input
              id="paymentFormFormUpdateExistingPaymentMethod-disclaimerCheckBox"
              name="paymentFormFormUpdateExistingPaymentMethod-disclaimerCheckBox"
              type="checkbox"
              className="paymentMethodInfo-disclaimerCheckBox required"
              ref={this.checkboxRef}
            />
            <label
              htmlFor="paymentFormFormUpdateExistingPaymentMethod-disclaimerCheckBox"
              className="paymentMethodInfo-disclaimerCheckBoxLabel"
            >
              I understand that the card I use today will become the default
              payment method across <strong>all</strong> Storyblocks sites.
            </label>
            <label
              htmlFor="paymentFormFormUpdateExistingPaymentMethod-disclaimerCheckBox"
              generated="true"
              className="error"
            >
              {this.state.errors.checkbox}
            </label>
          </div>
        )}
        {this.renderErrorMessage()}
        <div className="paymentMethodInfo-row">
          <div className="paymentMethodInfo-col right">
            <div className="paymentMethodInfo-col left" />
            <div className="paymentMethodInfo-col right">
              {this.renderButtonsOrProcessingIcon()}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderButtonsOrProcessingIcon() {
    return this.state.processing
      ? this.renderProcessingIcon()
      : this.renderSaveAndCancelButtons();
  }

  renderSaveAndCancelButtons() {
    const cancelButton = !this.shouldHideCancelButton() && (
      <button
        type="button"
        className="btn cancel"
        onClick={this.disableEditMode}
      >
        Cancel
      </button>
    );

    return (
      <React.Fragment>
        {cancelButton}
        <button
          type="button"
          className="btn save"
          id="paymentMethodInfo-save"
          onClick={this.saveCreditCardAndDisableEditMode}
        >
          {this.props.saveButtonText}
        </button>
      </React.Fragment>
    );
  }

  renderProcessingIcon() {
    return (
      <img
        src={`${__ASSETS_COMMON_IMAGES_URL__}/admin/loading.gif`}
        alt="Loading"
        width={16}
        height={16}
      />
    );
  }

  shouldHideCancelButton() {
    return (
      this.props.shouldForceUpdateViewOnly ||
      (!this.state.isTokenValid &&
        this.props.shouldHideCancelButtonWhenInvalidToken)
    );
  }

  renderErrorMessage() {
    return this.state.errorMessage ? (
      <div className="paymentMethodInfo-errorMessage">
        {this.state.errorMessage}
      </div>
    ) : (
      ''
    );
  }

  handleCreditCardFormChange(errors, fieldName, updatedValue) {
    this.setState({
      errors: errors || this.state.errors,
      newCreditCardData: {
        ...this.state.newCreditCardData,
        [fieldName]: updatedValue,
      },
    });
  }

  enableEditMode() {
    Telemetry.increment('paymentmethod.update.start');
    this.setState({
      successMessage: '',
    });
    this.toggleEditMode(true);
  }

  disableEditMode() {
    Telemetry.increment('paymentmethod.update.cancel');
    this.setState({
      newCreditCardData: {
        cc_number: '',
        cc_exp_full: '',
        cc_cvv: '',
      },
      errorMessage: '',
    });

    if (!this.props.isTokenValid) {
      this.props.getClosePopUpHandler()();
    }

    this.toggleEditMode(false);
  }

  toggleEditMode(shouldEdit) {
    this.setState({ editing: shouldEdit });
    if (this.props.toggleSubmitButton) {
      this.props.toggleSubmitButton({
        editing: shouldEdit,
      });
    }
  }

  submitCard() {
    this.setState({
      errorMessage: '',
      processing: true,
    });

    const { getJsonResponse } = this.props;

    const { newCreditCardData } = this.state;
    const data = {
      cc_number: newCreditCardData.cc_number,
      cc_exp_full: newCreditCardData.cc_exp_full,
      cc_cvv: newCreditCardData.cc_cvv,
    };

    if (newCreditCardData.full_name) {
      data.full_name = newCreditCardData.full_name;
    }

    if (newCreditCardData.address1) {
      data.address1 = newCreditCardData.address1;
    }

    if (newCreditCardData.city) {
      data.city = newCreditCardData.city;
    }

    if (newCreditCardData.state) {
      data.state = newCreditCardData.state;
    }

    if (newCreditCardData.zip) {
      data.zip = newCreditCardData.zip;
    }

    if (newCreditCardData.country) {
      data.country = newCreditCardData.country;
    }

    let headers = {
      'Content-Type': 'application/json',
    };

    if (getJsonResponse) {
      headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
    }

    const requestData = {
      body: JSON.stringify(data),
      method: 'POST',
      headers,
    };

    fetch(updatePaymentMethodRoute, requestData)
      .then((response) => {
        if (getJsonResponse) {
          return response.json();
        }
        return response;
      })
      .then((response) => {
        if (getJsonResponse && response.success) {
          Telemetry.increment('paymentmethod.update.success');
          return this.transitionToViewMode(response.data);
        }
        if (response.redirected) {
          Telemetry.increment('paymentmethod.update.success');
          return window.location.replace(response.url);
        }
        return this.onError(response);
      })
      .catch((err) => {
        this.onError(err);
      });
  }

  onError(data) {
    Telemetry.increment('paymentmethod.update.failure');
    this.setState({
      processing: false,
      errorMessage:
        data.message || 'There was an error updating your payment method',
    });
  }

  transitionToViewMode(data) {
    const updatedState = {
      editing: false,
      errorMessage: '',
      isTokenValid: true,
      processing: false,
    };

    if (this.props.configuration.postNewCreditCardOnSave) {
      updatedState.successMessage = 'Your payment method has been updated.';
    }

    this.setState({
      ...updatedState,
    });

    if (this.props.toggleSubmitButton) {
      const { newCreditCardData } = this.state;
      this.props.toggleSubmitButton({
        editing: false,
        newCreditCardData,
      });
    }

    if (this.props.onPaymentUpdate && data) {
      this.props.onPaymentUpdate(data);
    }
  }

  saveCreditCardAndDisableEditMode() {
    if (this.checkForRequiredFields()) {
      if (this.props.configuration.postNewCreditCardOnSave) {
        this.submitCard();
      } else {
        this.transitionToViewMode();
      }
    }
  }

  checkForRequiredFields() {
    const { errors } = this.state;

    if (!this.state.newCreditCardData.cc_number && !errors.cc_number) {
      errors.cc_number = 'This field is required.';
    }

    if (!this.state.newCreditCardData.cc_exp_full && !errors.cc_exp_full) {
      errors.cc_exp_full = 'This field is required.';
    }

    if (!this.state.newCreditCardData.cc_cvv && !errors.cc_cvv) {
      errors.cc_cvv = 'This field is required.';
    }

    if (
      !this.state.newCreditCardData.full_name &&
      !errors.full_name &&
      this.props.configuration.shouldShowNameField
    ) {
      errors.full_name = 'This field is required.';
    }

    if (
      !this.state.newCreditCardData.zip &&
      !errors.zip &&
      (this.props.configuration.shouldShowZipFieldOnly ||
        this.props.configuration.shouldShowAddressFields)
    ) {
      errors.zip = 'This field is required.';
    }

    if (
      this.props.showDisclaimerCheckbox &&
      !this.checkboxRef.current.checked
    ) {
      errors.checkbox = 'This field is required';
    } else {
      errors.checkbox = '';
    }

    this.setState({
      errors,
    });

    return this.hasValidUserSubmittedPaymentInfo();
  }

  hasValidUserSubmittedPaymentInfo() {
    let isValid = true;
    const { errors } = this.state;

    if (errors) {
      Object.values(errors).forEach((errorString) => {
        if (errorString.length > 0) {
          isValid = false;
        }
      });
    }
    return isValid;
  }
}
