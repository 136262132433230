import React, { useState } from 'react';

import ModalDialog from '../../app/Shared/ModalDialog';

const CvvDescriptionModal = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        className="border-none text-blue-500 hover:underline"
        onClick={() => setIsOpen(true)}
      >
        What is this?
      </button>
      <ModalDialog
        isOpen={isOpen}
        contentLabel="What is security code, CVV/CSC?"
        onRequestClose={() => setIsOpen(false)}
      >
        <img
          src={`${__ASSETS_COMMON_IMAGES_URL__}/cvv-info.jpg`}
          alt="CVV info"
          width={380}
          height={415}
        />
      </ModalDialog>
    </>
  );
};

export default CvvDescriptionModal;
