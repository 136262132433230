import React, { Component } from 'react';

import { ProgressiveImage } from '@videoblocks/storywind';

import PaymentSuccessMessage from './PaymentSuccessMessage';
import { PaypalConfiguration } from './PaypalCreateSubscription';
import UpdatePaypalAccount from './UpdatePaypalAccount';

interface ToggleSubmitButtonParams {
  editing: boolean;
  isTokenValid: boolean;
}

interface Props {
  onPaymentUpdate?: (data) => void;
  toggleSubmitButton?: (obj: ToggleSubmitButtonParams) => void;
  isTokenValid?: boolean;
  showPaymentDetailsHeader: boolean;
  getClosePopUpHandler?: () => void;
  shouldHideCancelButtonWhenInvalidToken: boolean;
  configuration: PaypalConfiguration;
  getJsonResponse: boolean;
  shouldForceUpdateViewOnly: boolean;
  paymentMethodUpdateRoute?: string;
  showDisclaimerCheckbox?: boolean;
}

interface State {
  editing: boolean;
  isTokenValid: boolean;
  message?: string;
}

export default class PaypalViewOrUpdate extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      editing: props.editing,
      isTokenValid: props.isTokenValid,
    };

    this.onUpdateSuccess = this.onUpdateSuccess.bind(this);
    this.enableEditMode = this.enableEditMode.bind(this);
    this.disableEditMode = this.disableEditMode.bind(this);
    this.onUpdateError = this.onUpdateError.bind(this);
  }

  render() {
    return this.shouldShowUpdateSection()
      ? this.renderUpdatePaymentMethodSection()
      : this.renderPaymentMethodOverview();
  }

  shouldShowUpdateSection() {
    return (
      this.state.editing ||
      !this.state.isTokenValid ||
      this.props.shouldForceUpdateViewOnly
    );
  }

  renderPaymentMethodOverview() {
    const header = this.props.showPaymentDetailsHeader ? (
      <div className="paymentMethodInfo-row">
        <div className="paymentMethodInfo-col left">
          <div className="paymentMethodInfo-paypalHeader">
            <strong>Payment Details:</strong>
          </div>
        </div>
      </div>
    ) : (
      ''
    );

    return (
      <React.Fragment>
        {header}
        <div className="paymentMethodInfo-row">
          <div className="paymentMethodInfo-col left">
            <ProgressiveImage
              src={__ASSETS_COMMON_IMAGES_URL__ + '/paypal-logo-with-text.png'}
              alt="PayPal"
              shouldLazyLoad={false}
              width={100}
              height={26}
            />
          </div>
          <div className="paymentMethodInfo-col right paypalUpdate-cancel">
            <div
              role="button"
              tabIndex={0}
              className="paymentMethodInfo-edit"
              onClick={this.enableEditMode}
              onKeyDown={this.enableEditMode}
            >
              Change PayPal Account
            </div>
          </div>
        </div>
        <PaymentSuccessMessage successMessage={this.state.message} />
      </React.Fragment>
    );
  }

  renderUpdatePaymentMethodSection() {
    const { paymentMethodUpdateRoute, configuration } = this.props;

    const cancelButton = this.shouldHideCancelButton() ? (
      ''
    ) : (
      <button
        type="button"
        className="btn cancel paymentMethodInfo-edit"
        onClick={this.disableEditMode}
      >
        Cancel
      </button>
    );

    return (
      <React.Fragment>
        <div className="paymentMethodInfo-paypalHeader paymentMethodInfo-paypalHeader-update">
          Sign in to PayPal to update your billing account
        </div>
        <UpdatePaypalAccount
          configuration={configuration}
          paymentMethodUpdateRoute={paymentMethodUpdateRoute}
          onUpdateSuccess={this.onUpdateSuccess}
          onUpdateError={this.onUpdateError}
          getJsonResponse={this.props.getJsonResponse}
        />
        {this.props.showDisclaimerCheckbox && (
          <div className="paymentMethodInfo-paypalDisclaimer paymentMethodInfo-disclaimer">
            <div className="paymentMethodInfo-disclaimerCheckBoxLabel">
              The payment method you use today will become the default payment
              method across <strong>all</strong> Storyblocks sites.
            </div>
          </div>
        )}
        {!this.props.shouldForceUpdateViewOnly && (
          <div className="paymentMethodInfo-row">
            <div className="paymentMethodInfo-col right">{cancelButton}</div>
          </div>
        )}
        {this.renderErrorMessage()}
      </React.Fragment>
    );
  }

  shouldHideCancelButton() {
    return (
      this.props.shouldHideCancelButtonWhenInvalidToken &&
      !this.state.isTokenValid
    );
  }

  renderErrorMessage() {
    return this.state.message ? (
      <div className="paymentMethodInfo-errorMessage">{this.state.message}</div>
    ) : (
      ''
    );
  }

  onUpdateSuccess(data) {
    this.setState({
      isTokenValid: true,
    });
    this.toggleEditMode(false, 'Your payment method has been updated');
    if (this.props.onPaymentUpdate && data) {
      this.props.onPaymentUpdate(data);
    }
  }

  onUpdateError() {
    this.toggleEditMode(
      true,
      'There was an error updating your payment method. Retry your request or contact customer support at support@storyblocks.com for more help.'
    );
  }

  enableEditMode() {
    this.toggleEditMode(true);
  }

  disableEditMode() {
    if (!this.state.isTokenValid && this.props.getClosePopUpHandler) {
      this.props.getClosePopUpHandler();
    } else {
      this.toggleEditMode(false);
    }
  }

  toggleEditMode(shouldEdit, message = '') {
    this.setState({
      editing: shouldEdit,
      message: message,
    });

    if (this.props.toggleSubmitButton) {
      this.props.toggleSubmitButton({
        editing: shouldEdit,
        isTokenValid: this.state.isTokenValid,
      });
    }
  }
}
