import luhn from 'luhn';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ProgressiveImage } from '@videoblocks/storywind';
import { Popper } from '@videoblocks/storywind';

import CvvDescriptionModal from '../PopUps/components/CvvDescriptionModal';
import DeviceDataInput from './DeviceDataInput';
import schemas from './schemas';

import './creditCardForm.less';

const minimumCharactersErrorMessage =
  'Please enter at least ${min} characters.';
const maximumCharactersErrorMessage =
  'Please enter no more than ${max} characters.';
const regExpWhiteSpaceBetweenTwoWords = /^([^\s]+\s+)+[^\s]+$/i;
const regExpDigitsOnly = /[0-9]+$/;
const regExpTwoDigitsBackslashTwoDigits = /^(\d){2}[/](\d){2}$/;
const regExpNonDigits = /\D/g;
const backspaceKeyCode = 8;

// TODO: Convert to TS when there is more time OMFG-1016 and TOFU-431, but if you see this, feel free to convert it too.
class CreditCardForm extends Component {
  static propTypes = {
    configuration: schemas.creditCardConfiguration.isRequired,
    onDataUpdated: PropTypes.func.isRequired,
    errors: PropTypes.shape({
      full_name: PropTypes.string,
      address1: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.string,
      country: PropTypes.string,
      cc_number: PropTypes.string,
      cc_exp_full: PropTypes.string,
      cc_cvv: PropTypes.string,
      pin: PropTypes.string,
    }).isRequired,
    isLoggedIn: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      full_name: '',
      address1: '',
      city: '',
      state: props.configuration.fieldLabels.defaultStateValue,
      zip: '',
      country: props.configuration.fieldLabels.defaultCountryValue,
      cc_number: '',
      cc_exp_full: '',
      cc_cvv: '',
      pin: '',
      errors: {
        full_name: props.errors.full_name,
        address1: props.errors.address1,
        city: props.errors.city,
        state: props.errors.state,
        zip: props.errors.zip,
        country: props.errors.country,
        cc_number: props.errors.cc_number,
        cc_exp_full: props.errors.cc_exp_full,
        cc_cvv: props.errors.cc_cvv,
        pin: props.errors.pin,
      },
      showCvvDescriptionTooltip: false,
    };

    this.setValue = this.setValue.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.handleExpirationDateKeyUp = this.handleExpirationDateKeyUp.bind(this);
    this.handleCvvDescriptionClick = this.handleCvvDescriptionClick.bind(this);
  }

  componentDidUpdate(previousProps) {
    if (previousProps.errors !== this.state.errors) {
      this.setState({
        errors: this.props.errors,
      });
    }
  }

  validateFields(event) {
    const { value, name } = event.target;
    const { errors } = this.state;

    switch (name) {
      case 'full_name':
        errors[name] = this.getErrorsForFullName(value);
        break;
      case 'address1':
        errors[name] = this.getErrorsForAddress1(value);
        break;
      case 'city':
        errors[name] = this.getErrorsForCity(value);
        break;
      case 'zip':
        errors[name] = this.getErrorsForZip(value);
        break;
      case 'cc_number':
        errors[name] = this.getErrorsForCreditCardNumber(value);
        break;
      case 'cc_exp_full':
        errors[name] = this.getErrorsForExpirationDate(value);
        break;
      case 'cc_cvv':
        errors[name] = this.getErrorsForCvv(value);
        break;
      case 'pin':
        errors[name] = this.getErrorsForPin(value);
        break;
      default:
        break;
    }

    this.setState({
      errors,
      [name]: value,
    });
    this.props.onDataUpdated(errors, name, value);
  }

  setValue(event) {
    const { name, value } = event.target;

    if (this.state.errors[name]) {
      this.validateFields(event);
    } else {
      this.setState({
        [name]: value,
      });
      this.props.onDataUpdated(null, name, value);
    }
  }

  // format the expiration date to automatically add a backslash after 2 characters,
  // only allows 5 characters and only digits (ignores entry of anything else)
  handleExpirationDateKeyUp(event) {
    if (event.keyCode !== backspaceKeyCode && event.target.value.length <= 5) {
      const input = event.target.value;
      let formattedInput = input.replace(regExpNonDigits, '');
      if (formattedInput.length > 1 && formattedInput.length <= 5) {
        formattedInput =
          formattedInput.length === 2
            ? `${formattedInput}/`
            : `${formattedInput.substring(0, 2)}/${formattedInput.substring(
                2,
                4
              )}`;
      }
      event.target.value = formattedInput;
      this.setValue(event);
    } else if (event.keyCode === backspaceKeyCode) {
      this.setValue(event);
    }
  }

  render() {
    const { configuration } = this.props;
    const { errors } = this.state;
    return (
      <fieldset>
        <div className="creditCardForm-row">
          {configuration.shouldShowNameField && (
            <div className="creditCardForm-item">
              <label className="creditCardForm-label" htmlFor="cc_full_name">
                Full Name
              </label>
              <input
                className="creditCardForm-input disabled:bg-gray-200"
                id="cc_full_name"
                name="full_name"
                type="text"
                placeholder="First Last"
                value={this.state.full_name}
                autocomplete="name"
                onChange={this.setValue}
                onBlur={this.validateFields}
              />
              {errors.full_name && (
                <label htmlFor="full_name" className="error">
                  {errors.full_name}
                </label>
              )}
            </div>
          )}
        </div>
        {configuration.shouldShowAddressFields && (
          <>
            <div className="creditCardForm-row">
              <div className="creditCardForm-item">
                <label htmlFor="address1" className="creditCardForm-label">
                  Address
                </label>
                <input
                  name="address1"
                  className="creditCardForm-input disabled:bg-gray-200"
                  type="text"
                  id="address1"
                  placeholder="Address"
                  value={this.state.address1}
                  autocomplete="street-address"
                  onChange={this.setValue}
                  onBlur={this.validateFields}
                />
                {errors.address1 && (
                  <label htmlFor="address1" className="error">
                    {errors.address1}
                  </label>
                )}
              </div>
            </div>

            <div className="creditCardForm-row">
              <div className="creditCardForm-item">
                <label htmlFor="city" className="creditCardForm-label">
                  City
                </label>
                <input
                  name="city"
                  className="creditCardForm-input disabled:bg-gray-200"
                  type="text"
                  id="city"
                  placeholder="City"
                  value={this.state.city}
                  autocomplete="address-level2"
                  onChange={this.setValue}
                  onBlur={this.validateFields}
                />
                {errors.city && (
                  <label htmlFor="city" className="error">
                    {errors.city}
                  </label>
                )}
              </div>

              <div className="creditCardForm-item">
                <label htmlFor="state" className="creditCardForm-label">
                  {configuration.fieldLabels.stateLabel}
                </label>
                <select
                  id="state"
                  name="state"
                  className="creditCardForm-input disabled:bg-gray-200"
                  data-style="btn-default"
                  autocomplete="address-level1"
                  defaultValue={configuration.fieldLabels.defaultStateValue}
                  onChange={this.setValue}
                >
                  {configuration.states.map((state, index) => {
                    return (
                      <option
                        value={state.abbreviation}
                        key={`${state.abbreviation}-${index}`}
                      >
                        {state.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="creditCardForm-row">
              <div className="creditCardForm-item">
                <label className="creditCardForm-label" htmlFor="zip">
                  {configuration.fieldLabels.zipLabel}
                </label>
                <input
                  className="creditCardForm-input disabled:bg-gray-200"
                  name="zip"
                  type="text"
                  placeholder="12345"
                  autocomplete="postal-code"
                  value={this.state.zip}
                  onChange={this.setValue}
                  onBlur={this.validateFields}
                  data-private="redact"
                />
                {errors.zip && (
                  <label htmlFor="zip" className="error">
                    {errors.zip}
                  </label>
                )}
              </div>

              <div className="creditCardForm-item">
                <label htmlFor="country" className="creditCardForm-label">
                  Country
                </label>
                <select
                  id="country"
                  name="country"
                  className="creditCardForm-input disabled:bg-gray-200"
                  data-style="btn-default"
                  autocomplete="country"
                  defaultValue={configuration.fieldLabels.defaultCountryValue}
                  onChange={this.setValue}
                >
                  {configuration.countries.map((country) => {
                    return (
                      <option
                        value={country.abbreviation}
                        key={country.abbreviation}
                      >
                        {country.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </>
        )}
        {configuration.shouldShowZipFieldOnly && (
          <div className="creditCardForm-row">
            <div className="creditCardForm-item">
              <label className="creditCardForm-label" htmlFor="zip">
                {configuration.fieldLabels.zipLabel}{' '}
              </label>
              <input
                className="creditCardForm-input disabled:bg-gray-200"
                id="cc_zip"
                name="zip"
                type="text"
                placeholder="12345"
                autocomplete="postal-code"
                value={this.state.zip}
                onChange={this.setValue}
                onBlur={this.validateFields}
              />
              {errors.zip && (
                <label htmlFor="zip" className="error">
                  {errors.zip}
                </label>
              )}
            </div>

            <div className="creditCardForm-item">
              <label htmlFor="country" className="creditCardForm-label">
                Country
              </label>
              <select
                id="country"
                name="country"
                className="creditCardForm-input disabled:bg-gray-200"
                data-style="btn-default"
                autocomplete="country"
                defaultValue={configuration.fieldLabels.defaultCountryValue}
                onChange={this.setValue}
              >
                {configuration.countries.map((country) => {
                  return (
                    <option
                      value={country.abbreviation}
                      key={country.abbreviation}
                    >
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        )}
        <div className="creditCardForm-row">
          <div className="creditCardForm-item">
            <div className="creditCardForm-creditCardRowLabel">
              <label className="creditCardForm-label" htmlFor="cc_number">
                Credit Card Number
              </label>
              <img
                className="creditCardForm-creditCardPictures"
                src={`${__ASSETS_COMMON_IMAGES_URL__}/credit-card-icons.png`}
                alt="Credit Cards"
                width={100}
                height={14}
              />
            </div>
            <input
              className="creditCardForm-input disabled:bg-gray-200"
              id="cc_number"
              name="cc_number"
              type="text"
              placeholder="4444-4444-4444-4444"
              autocomplete="cc-number"
              value={this.state.cc_number}
              onChange={this.setValue}
              onBlur={this.validateFields}
              data-private="redact"
            />
            {errors.cc_number && (
              <label htmlFor="cc_number" className="error">
                {errors.cc_number}
              </label>
            )}
          </div>
        </div>
        <div className="creditCardForm-row">
          <div className="creditCardForm-item">
            <div className="creditCardForm-creditCardRowLabel">
              <label className="creditCardForm-label" htmlFor="cc_exp_full">
                Expiration Date
              </label>
            </div>
            <input
              className="creditCardForm-input disabled:bg-gray-200"
              id="cc_exp_full"
              name="cc_exp_full"
              type="text"
              placeholder="MM/YY"
              autocomplete="cc-exp"
              maxLength="5"
              value={this.state.cc_exp_full}
              onKeyUp={this.handleExpirationDateKeyUp}
              onChange={this.setValue}
              onBlur={this.validateFields}
              data-private="redact"
            />
            {errors.cc_exp_full && (
              <label htmlFor="cc_exp_full" className="error">
                {errors.cc_exp_full}
              </label>
            )}
          </div>
          <div className="creditCardForm-item">
            <div className="creditCardForm-creditCardRowLabel">
              <label className="creditCardForm-label">
                {configuration.fieldLabels.cvvLabel}
              </label>
            </div>
            <input
              className="creditCardForm-input disabled:bg-gray-200"
              id="cc_cvv"
              name="cc_cvv"
              type="text"
              placeholder="123"
              autocomplete="cc-csc"
              value={this.state.cc_cvv}
              onChange={this.setValue}
              onBlur={this.validateFields}
              data-private="redact"
            />
            {errors.cc_cvv && (
              <label htmlFor="cc_cvv" className="error">
                {errors.cc_cvv}
              </label>
            )}

            {configuration.shouldShowCvvDescription &&
              this.renderCvvDescription()}
          </div>
        </div>
        {configuration.shouldShowPinField && (
          <div className="creditCardForm-row">
            <>
              <div className="creditCardForm-item">
                <label className="creditCardForm-label" htmlFor="pin">
                  {configuration.fieldLabels.pinLabel}{' '}
                </label>
                <input
                  className="creditCardForm-input disabled:bg-gray-200"
                  name="pin"
                  type="text"
                  placeholder=""
                  value={this.state.pin}
                  onChange={this.setValue}
                  onBlur={this.validateFields}
                  data-private="redact"
                />
                {errors.pin && (
                  <label htmlFor="pin" className="error">
                    {errors.pin}
                  </label>
                )}
              </div>
              <div className="creditCardForm-item"></div>
            </>
          </div>
        )}
        <DeviceDataInput
          braintreeClientToken={configuration.braintreeClientToken}
          onLoad={(value) =>
            this.props.onDataUpdated(null, 'device_data', value)
          }
        />
      </fieldset>
    );
  }

  handleCvvDescriptionClick = () => {
    this.setState({
      showCvvDescriptionTooltip: !this.state.showCvvDescriptionTooltip,
    });
  };

  renderCvvDescription() {
    return this.props.configuration?.useTextTooltipForCvvPopup ? (
      <div>
        <Popper
          hideContent={() =>
            this.setState({ showCvvDescriptionTooltip: false })
          }
          hideOnResize={false}
          hideOnScroll={false}
          popperClasses="bg-white shadow-popover p-4"
          showContent={this.state.showCvvDescriptionTooltip}
          triggerElement={(referenceElement) => (
            <a
              className="cursor-help"
              href="#"
              onClick={this.handleCvvDescriptionClick}
              onKeyDown={this.handleCvvDescriptionClick}
              onMouseEnter={() =>
                this.setState({ showCvvDescriptionTooltip: true })
              }
              onFocus={() => this.setState({ showCvvDescriptionTooltip: true })}
              ref={referenceElement}
            >
              What is this?
            </a>
          )}
        >
          <ProgressiveImage
            src="/assets/common/images/cvv-info.jpg"
            shouldLazyLoad={false}
          />
        </Popper>
      </div>
    ) : (
      <CvvDescriptionModal />
    );
  }

  getErrorsForFullName(fullName) {
    if (fullName.length < 2 && fullName.length > 0) {
      return minimumCharactersErrorMessage.replace('${min}', '2');
    }
    if (fullName.length > 255) {
      return maximumCharactersErrorMessage.replace('${max}', '255');
    }
    if (!regExpWhiteSpaceBetweenTwoWords.test(fullName.trim())) {
      return 'Please enter your first and last name.';
    }
    return '';
  }

  getErrorsForAddress1(address1) {
    if (address1.length < 2 && address1.length > 0) {
      return minimumCharactersErrorMessage.replace('${min}', '2');
    }
    if (address1.length > 255) {
      return maximumCharactersErrorMessage.replace('${max}', '255');
    }
    return '';
  }

  getErrorsForCity(city) {
    if (city.length < 2 && city.length > 0) {
      return minimumCharactersErrorMessage.replace('${min}', '2');
    }
    if (city.length > 255) {
      return maximumCharactersErrorMessage.replace('${max}', '255');
    }
    return '';
  }

  getErrorsForZip(zip) {
    if (zip.length < 4) {
      return minimumCharactersErrorMessage.replace('${min}', '4');
    }
    return '';
  }

  getErrorsForCreditCardNumber(value) {
    if (value.length < 14) {
      return minimumCharactersErrorMessage.replace('${min}', '14');
    }
    if (value.length > 19) {
      return maximumCharactersErrorMessage.replace('${max}', '19');
    }
    // Luhn test is used to validate credit card numbers
    if (!regExpDigitsOnly.test(value) || !luhn.validate(value)) {
      return 'Please enter a valid credit card number.';
    }
    return '';
  }

  getErrorsForCvv(value) {
    if (value.length < 3) {
      return minimumCharactersErrorMessage.replace('${min}', '3');
    } else if (value.length > 4) {
      return maximumCharactersErrorMessage.replace('${max}', '4');
    } else if (!regExpDigitsOnly.test(value)) {
      return 'Please enter only digits.';
    }
    return '';
  }

  getErrorsForPin(value) {
    if (value.length < 7) {
      return minimumCharactersErrorMessage.replace('${min}', '7');
    }
    if (value.length > 18) {
      return maximumCharactersErrorMessage.replace('${max}', '18');
    }

    return '';
  }

  getErrorsForExpirationDate(expirationDate) {
    const currentYear = parseInt(
      new Date().getFullYear().toString().substr(-2),
      10
    );
    const correctFormat =
      regExpTwoDigitsBackslashTwoDigits.test(expirationDate);
    const expDateArray = expirationDate.split('/');
    const expMonth = parseInt(expDateArray[0], 10);
    const expYear = parseInt(expDateArray[1], 10);
    const validMonthExpiry = expMonth > 0 && expMonth <= 12;
    const validYearExpiry = expYear >= currentYear;

    let validFullExpiry = true;
    if (currentYear === expYear) {
      let currentMonth = new Date().getMonth() + 1;
      validFullExpiry = currentMonth <= expMonth;
    }
    if (
      !(correctFormat && validMonthExpiry && validYearExpiry && validFullExpiry)
    ) {
      return 'Please enter a valid credit card expiration date (MM/YY)';
    }
    return '';
  }
}

export default connect()(CreditCardForm);
